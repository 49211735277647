@import url("https://fonts.googleapis.com/css2?family=Cairo&display=swap");
html,
body {
  padding: 0;
  margin: 0;
  font-family: "Cairo", sans-serif !important;
  box-sizing: border-box;
}

.Export {
    padding: 1em 2.1em 1.1em;
    border-radius: 3px;
    margin: 8px 8px 8px 8px;
    display: inline-block;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    font-family: sans-serif;
    font-weight: 800;
    font-size: 0.85em;
    text-align: center;
    text-decoration: none;
    background-color:#1D6F42;
    padding: 0 12px;
    border: none;
    height: 30px;
  color: white;
    font-size: 12px;
    border-radius: 8px;
    margin: 8px;
    cursor: pointer !important;
    &:active {
      transform: translateY(1px) translateX(1px);
    }
    &:hover {
      opacity: 0.5;
    }
  }

.CreateTask {
    padding: 1em 2.1em 1.1em;
    border-radius: 3px;
    margin: 8px 8px 8px 8px;
    display: inline-block;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    font-family: sans-serif;
    font-weight: 800;
    font-size: 0.85em;
    text-align: center;
    text-decoration: none;
    background-color: tomato;
    padding: 0 12px;
    border: none;
    height: 30px;
  color: white;
    font-size: 12px;
    border-radius: 8px;
    margin: 8px;
    cursor: pointer;
    &:active {
      transform: translateY(1px) translateX(1px);
    }
    &:hover {
      opacity: 0.5;
    }
  }
  .table_container {
    overflow: hidden;
    margin: auto;
    width: 100%;
    height: 90vh;
    
    .thead {
      border-bottom: 1px solid #e5e5e5;
      width: 98%;

      display: flex;
      align-items: center;
      padding: 5px 0;
      color: #b0b0b0;
      margin-top: 2px;
      font-size: 12px;
      font-style: normal;
      font-weight: 800;
      text-align: center;
      .small_width {
        width: 30% !important;
      }
      span {
        width: 100% !important;
      }
    }
    .odd_row {
      background: #f0f0f0;
    }
    .row_odd {
      height: 90% !important;
      overflow-y: scroll;
      margin-top: 10px;
  
      div:nth-child(even) {
        background: #f0f0f0;
      }
    }
    .row_even {
      height: 85% !important;
  
      div:nth-child(even) {
        background: #f0f0f0;
      }
    }
    .body_container {
      width: 98%;
      border: 1px solid #e5e5e5;
      border-radius: 8px;
      padding: 15px 0;
      color: #000;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      display: flex;
      align-items: center;
      margin: 15px 2px;
      text-align: center;
      cursor: pointer;
      .small_width {
        width: 30% !important;
      }
      span {
        width: 100%;
      }
    }
  }
  .body_container:hover {
    background-color:#eee;
  }

  .SearchBar{
      border: 1px solid #e5e5e5;
      border-radius: 4px;
      font-size: 15px;
      outline: none;
      height: 35px;
      padding: 2px 5px;
      width: 400px;
  }

  .InputField{
    border: 1px solid #e5e5e5;
      border-radius: 4px;
      font-size: 15px;
      outline: none;
      height: 35px;
      padding: 2px 5px;
      width: 200px;
  }

  .Close{
    padding: 1em 2.1em 1.1em;
    border-radius: 3px;
    margin: 8px 8px 8px 8px;
    display: inline-block;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    font-family: sans-serif;
    font-weight: 800;
    font-size: 0.85em;
    text-align: center;
    text-decoration: none;
    background-color: tomato;
    padding: 0 12px;
    border: none;
    height: 30px;
    width: 65px;
  color: white;
    font-size: 12px;
    border-radius: 8px;
    margin: 8px;
    // cursor: pointer;
    &:active {
      transform: translateY(1px) translateX(1px);
    }
    &:hover {
      opacity: 0.5;
    }
  }

  .AiFillHome{
    color: tomato;
  }

  .login-container {
    // border: 1px solid black;
    // padding: 32px;
    width: 500px;
    margin: 0 auto;
  
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    align-items: center;
    height: 100vh;
    .logo {
      margin-top: 128px;
    }
  }
  .login-left-side {
    width: 100%;
    .login-headding {
      margin: 0;
      margin-bottom: 8px;
    }
    .login-text {
      margin: 0;
      margin-bottom: 24px;
    }
    .login-form {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .input {
  padding: 0 16px;
  height: 40px;
  margin: 4px 0;
  border: 1px solid #f5f5f5;
  background-color: #f5f5f5;
  border-radius: 8px;
  min-width: 240px;

  &:focus {
    border: 1px solid #eb0028;
  }
}
.button {
  padding: 1em 2.1em 1.1em;
  border-radius: 3px;
  margin: 8px 8px 8px 8px;
  display: inline-block;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  font-family: sans-serif;
  font-weight: 800;
  font-size: 15px !important;
  text-align: center;
  text-decoration: none;
  letter-spacing: 1px;

  padding: 0 12px;
  border: none;
  height: 40px;

  font-size: 12px;
  border-radius: 8px;
  margin: 8px;
  cursor: pointer;
  &:active {
    transform: translateY(1px) translateX(1px);
  }
  &:hover {
    opacity: 0.5;
  }
}
    .logo {
      @media only screen and (max-height: 520px) {
        visibility: hidden;
      }
    }
  }
  
  .c70b44d {
  color: white;
  background-color: #70b44d;
  &:hover {
    background-color: #4d9429;
  }
}
.ff1d43 {
  color: white;
  background-color: #ff1d43;
  &:hover {
    background-color: #ee7387;
  }
}
.c8597A5 {
  color: white;
  background-color: #8597a5 !important;
  &:hover {
    background-color: #788085;
  }
}

.ceb0028 {
  color: white;
  background-color: #eb0028;
  &:hover {
    background-color: #ca0022;
  }
}
.spinnerDiv {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99999;
  padding: 0 auto !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.loading_screen {
  direction: rtl;
  text-align: left;
  height: 100vh;
  display: flex;
  align-items: center;
  font-size: 3vw;
  color: #ff0000;
  font-weight: 600;
  letter-spacing: 1.4px;
  justify-content: center;
  text-align: center;
  .tamata_title {
    font-size: 30px;
    span {
      color: black;
    }
  }
}
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(196, 196, 196);
  border-radius: 10px;
}
.Sucessful{
  
  padding: 0px 10px;
    border-radius: 10px;
    line-height: 30px;
    color: white;
    margin: 10px 0;
    background: #70b44d;
    color: #ffffff !important;
    font-size:small;
    font-weight: 800;


}

.Failed{
  padding: 0px 10px;
  border-radius: 10px;
  line-height: 30px;
  margin: 10px 0;
  background: #ff0c35;
  font-size: 10xp;
  color: #ffffff !important;
  font-size:small;
  font-weight: 800;


}
.Processing{
  padding: 0px 10px;
    border-radius: 10px;
    line-height: 30px;
    margin: 10px 0;
    background: #fea120;
    color: #ffffff !important;
    font-size:small;
    font-weight: 800;

//hello what the hell is this temesliting are u out of ur damn mind u mother father just do ur damn job u only have this one joooob
}